// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["gender", "ballistic", "performance", "direction", "submit"]
  static classes = ['display']


  connect() {
    //this.genderTarget.classList.add(this.displayClass)
  }

  change_question(event) {
    let target = event.currentTarget.dataset.target
    var targetNode

    switch(target) {
      case 'gender':
        targetNode = this.genderTarget
        break
      case 'ballistic':
        targetNode = this.ballisticTarget
        break
      case 'performance':
        targetNode = this.performanceTarget
        break
      case 'direction':
        targetNode = this.directionTarget
        break
      case 'submit':
        targetNode = this.submitTarget
        break
    }
    console.log(targetNode)
    event.currentTarget.closest("section").classList.remove(this.displayClass)
    targetNode.classList.add(this.displayClass)
  }
}
