import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "post", "show" ]
  static values = { score: Number }

  connect() {
    //if (this.hasPostTarget) {
    //  $(this.postTarget).raty({
    //    number: 5,
    //    score : 3,
    //    starOn: "/images/star-on.png",
    //    starOff: "/images/star-off.png",
    //    starHalf: "/images/star-half.png",
    //    scoreName: 'review[score]',
    //    half: false,
    //  })
    //}
    //if (this.hasShowTarget) {
    //  $(this.showTarget).raty({
    //    readOnly: true,
    //    score: function() {
    //      //return this.scoreValue;
    //      return $(this).attr('data-raty-score-value');
    //    },
    //    path: '/images/',
    //  })
    //}
  }
}
