import Rails from "@rails/ujs"
window.Rails = Rails
//import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
//Turbolinks.start()
ActiveStorage.start()

import "controllers"

//import '../stylesheets/application'
import '../../views/layouts/style.scss'

var jQuery = require('jquery')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

window.Cookies = require("js-cookie")
require('js/favorite')

require('packs/raty')
require('packs/jquery.toc')
