
$(function(){
  $('.favorite').on('click', function () {
    let item = "", itmes = "", split = [];
    item = $(this).data('id');
    cookieName = $(this).data('name');
    className = $(this).attr("class");
    items = Cookies.get(cookieName);
    if (className.match(/off/)) {
      if (items != undefined) {
        items = items + "," + item;
        split = items.split(",");
        // 重複を除去します。
        dup = split.filter(function (a, c, b) {
          return b.indexOf(a) === c;
        });
        Cookies.set(cookieName, dup.join(","));
      } else {
        Cookies.set(cookieName, item);
      }
      $(this).addClass("is-active").removeClass("off");
    } else if (className.match(/is-active/)) {
      split = items.split(",");
      var res = split.filter(function (a) {
        return a.toString() !== item.toString();
      });
      if (res.length == 0) {
        Cookies.remove(cookieName);
      } else {
        Cookies.set(cookieName, res.join(","));
      }
      $(this).addClass("off").removeClass("is-active");
    }
  });
});