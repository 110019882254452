import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "select", "hidden"]
  static values = {
    productsUrl: String,
    stylingUrl: String,
    columnsUrl: String,
  }

  // ページ表示時に発火
  connect() {
    this.setForm(Number(this.selectTarget.value))
  }

  // オプション変更時に発火
  optionChange(event) {
    this.setForm(Number(event.target.value))
  }

  // 検索ボタンを押した際にフォームを送信
  form_submit(){
    this.formTarget.submit()
  }

  // 選択されてるオプションによってフォームを書き換え
  setForm(optionValue) {
    // formのacrionに設定する内容
    let targetUrl
    // 商品検索がクラブかウェアかを判断するための要素を作成
    var element = document.createElement("input")
    element.type = "hidden"
    element.name = "category_id"
    element.setAttribute("data-header-search-target", "hidden")
    // オプションの値で処理分岐
    switch (optionValue) {
      case 0:
        targetUrl = this.productsUrlValue
        element.value = 1
        this.setHidden(element)
        break
      case 1:
        targetUrl = this.productsUrlValue
        element.value = 4
        this.setHidden(element)
        break
      case 2:
        targetUrl = this.stylingUrlValue
        this.removeHidden()
        break
      case 3:
        targetUrl = this.columnsUrlValue
        this.removeHidden()
        break
      case 4:
        targetUrl = this.productsUrlValue
        element.name = "genre_id"
        element.value = 9
        this.setHidden(element)
        break
    }
    this.formTarget.setAttribute('action', targetUrl)
  }

  // パラメータを追加もしくは置き換え
  setHidden(element){
    if (this.hasHiddenTarget) {
      this.hiddenTarget.replaceWith(element)
    } else {
      this.formTarget.append(element)
    }
  }

  // パラメータを削除
  removeHidden(){
    if (this.hasHiddenTarget) {
      this.hiddenTarget.remove()
    }
  }
}
