import { Controller } from "stimulus"

export default class extends Controller {
  static values = { product: Number }

  connect() {
    const product_id = String(this.productValue)
    const cookie_name = 'recently_viewed_products'
    var viewed_ids = []
    // 既にクッキーが存在している場合は、Cookieの内容を配列にして格納
    if(Cookies.get(cookie_name)){
      viewed_ids = Cookies.get(cookie_name).split(",")
    }
    // 既にCookie上にIDが存在する場合は一度削除してもう一度追加して順番を変更
    if($.inArray(product_id, viewed_ids) !== -1){
      viewed_ids = viewed_ids.filter(n => n !== product_id)
      viewed_ids.push(product_id)
    // 存在していなければ、product_idを配列に追加
    } else {
      viewed_ids.push(product_id)
    }
    // 6個を超えた場合には1つ削除
    if (viewed_ids.length > 6){
      viewed_ids.shift()
    }
    // 配列をストリングに変換してクッキ―に保存
    Cookies.set(cookie_name, viewed_ids.join(','))
  }
}