import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "content", "submit" ]
  static classes = [ "posted" ]
  static get values() {
    return { enable: Boolean };
  }

  connect() {
    this.contentTarget.addEventListener('input', this, false);
  }

  disconnect() {
    this.contentTarget.removeEventListener('input', this, false);
  }

  handleEvent(event) {
    switch (event.type) {
      case 'input':
        this.enableValue = this.contentTarget.value.length > 0;
        break;
    }
  }

  enableValueChanged() {
    this.submitTarget.disabled = !this.enableValue;
  }

  submit() {
    var div_element = document.createElement('div');
    div_element.className = 'posted';
    div_element.textContent = this.formTarget.dataset.thanksText;
    this.formTarget.replaceWith(div_element);
  }
}